import React, { useEffect } from "react";
import { FormsyInjectedProps, withFormsy } from "formsy-react";
import Select from "react-select";
import countriesWithPrefix from "./countriesWithPrefix.json";
import { CollectingSelectOption } from "../../Types";

type PrefixSelectProps = {
  errorMessage: string;
  showErrors: boolean;
  setErrorMessage: (msg: string) => void;
} & FormsyInjectedProps<CollectingSelectOption | null>;

const PrefixSelect: React.FunctionComponent<PrefixSelectProps> = ({
  setValue,
  value,
  errorMessage,
  showErrors,
  setErrorMessage,
}): React.ReactElement => {
  const options = countriesWithPrefix.map((item) => ({
    value: `+${item.callingCode}`,
    label: `${item.countryCode} +${item.callingCode}`,
  }));
  const showError = showErrors && errorMessage;

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage, setErrorMessage]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_PHONE_COUNTRY_NUMBER) {
      const defaultCountry = countriesWithPrefix.find(
        (prefix) =>
          `+${prefix.callingCode}` ===
          process.env.NEXT_PUBLIC_PHONE_COUNTRY_NUMBER
      );
      if (defaultCountry) {
        setValue({
          value: `+${defaultCountry.callingCode}`,
          label: `${defaultCountry.countryCode} +${defaultCountry.callingCode}`,
        });
      }
    }
  }, []);

  return (
    <label>
      <Select
        instanceId="prefixSelect"
        classNamePrefix="Select"
        className={showError ? "Select hasError" : "Select"}
        value={value}
        onChange={(event) => setValue(event ? event : null)}
        options={options}
        isSearchable={false}
        isClearable={false}
      />
    </label>
  );
};

export default withFormsy(PrefixSelect);
