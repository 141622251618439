import React, { useState } from "react";
import Formsy from "formsy-react";
import PhoneNumberWithPrefixInput from "./PhoneNumberInput/PhoneNumberWithPrefixInput";
import { CollectingLeadsBlockData, LeadData } from "../Types";
import TextInput from "./TextInput/TextInput";
import CollectingSelect from "./CollectingSelect/CollectingSelect";
import CollectingTextArea from "./CollectingTextArea/CollectingTextArea";
import { CollectingQuestion } from "../constraints";
import ContentUnderneath from "./ContentUnderneath/ContentUnderneath";
import CollectingCheckbox from "./CollectingCheckbox/CollectingCheckbox";
import { useTranslation } from "next-i18next";
import { CityOption } from "../../../../types/CityOption";

type CollectingLeadsFormProps = {
  data: CollectingLeadsBlockData;
  cities: Array<CityOption>;
  anchorId: string;
  questionsArray: Array<CollectingQuestion>;
  handleSubmit: (data: LeadData) => void;
  validationServerErrors: Record<string, string | null>;
};

const CollectingLeadsForm: React.FunctionComponent<
  CollectingLeadsFormProps
> = ({
  data,
  cities,
  anchorId,
  questionsArray,
  handleSubmit,
  validationServerErrors,
}): React.ReactElement => {
  const [hasErrors, setHasErrors] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formId = anchorId ? anchorId + "_form" : undefined;
  const { t } = useTranslation();

  const handleValid = (): void => {
    if (hasErrors) {
      setHasErrors(false);
    }
  };

  const handleInvalid = (): void => {
    if (!hasErrors) {
      setHasErrors(true);
    }
  };

  const getTextLabel = (label: string, isRequired = false): string =>
    `${t(label)}${isRequired ? "*" : ""}`;

  const getDefaultValidationRequired = (
    isRequired = false,
    message = "dataForm-requiredValidation"
  ): { isDefaultRequiredValue: string } | undefined =>
    isRequired
      ? {
          isDefaultRequiredValue: t(message),
        }
      : undefined;

  const getEmailValidation = (
    isRequired = false,
    message = "dataForm-requiredValidation"
  ): { isDefaultRequiredValue: string; isEmail: string } | undefined =>
    isRequired
      ? {
          isDefaultRequiredValue: t(message),
          isEmail: t("dataForm-emailValidation"),
        }
      : undefined;

  const {
    nameLead,
    nameLeadRequired,
    surname,
    surnameRequired,
    company,
    companyRequired,
    positionLead,
    positionLeadRequired,
    email,
    emailRequired,
    phone,
    phoneRequired,
    buttonText,
    city,
    cityRequired,
    contentUnderneath,
    contentUnderneathCollapsed,
    contentForConsent,
  } = data;

  const showErrors = isSubmitted && hasErrors;

  return (
    <div className="collectingLeads__form">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={handleValid}
        onInvalid={handleInvalid}
        validationErrors={validationServerErrors}
        id={formId}
      >
        {nameLead && (
          <TextInput
            name="name"
            required={nameLeadRequired}
            placeholder={t("dataForm-name")}
            label={getTextLabel("dataForm-name", nameLeadRequired)}
            validationErrors={getDefaultValidationRequired(nameLeadRequired)}
            showErrors={showErrors}
          />
        )}

        {surname && (
          <TextInput
            name="surname"
            required={surnameRequired}
            placeholder={t("dataForm-lastName")}
            label={getTextLabel("dataForm-lastName", surnameRequired)}
            validationErrors={getDefaultValidationRequired(surnameRequired)}
            showErrors={showErrors}
          />
        )}

        {company && (
          <TextInput
            name="company"
            required={companyRequired}
            placeholder={t("dataForm-companyName")}
            label={getTextLabel("dataForm-companyName", companyRequired)}
            validationErrors={getDefaultValidationRequired(companyRequired)}
            showErrors={showErrors}
          />
        )}

        {positionLead && (
          <TextInput
            name="position"
            required={positionLeadRequired}
            placeholder={t("dataForm-position")}
            label={getTextLabel("dataForm-position", positionLeadRequired)}
            validationErrors={getDefaultValidationRequired(
              positionLeadRequired
            )}
            showErrors={showErrors}
          />
        )}

        {email && (
          <TextInput
            name="email"
            type="email"
            required={emailRequired}
            validations="isEmail"
            placeholder={t("dataForm-email")}
            label={getTextLabel("dataForm-email", emailRequired)}
            validationErrors={getEmailValidation(emailRequired)}
            showErrors={showErrors}
          />
        )}

        {phone && (
          <PhoneNumberWithPrefixInput
            required={phoneRequired}
            showErrors={showErrors}
            placeholder={t("dataForm-phone")}
            t={t}
            label={getTextLabel("dataForm-phone", phoneRequired)}
          />
        )}

        {city && (
          <CollectingSelect
            options={cities}
            name="city"
            required={cityRequired}
            placeholder={t("dataForm-chooseCity")}
            label={getTextLabel("dataForm-city", cityRequired)}
            showErrors={showErrors}
            validationErrors={getDefaultValidationRequired(cityRequired)}
          />
        )}

        {questionsArray.map((question) => (
          <CollectingTextArea
            key={question.name}
            name={question.name}
            required={question.required}
            label={question.label}
            showErrors={showErrors}
            validationErrors={getDefaultValidationRequired(question.required)}
          />
        ))}

        <ContentUnderneath
          contentUnderneath={contentUnderneath}
          contentUnderneathCollapsed={contentUnderneathCollapsed}
          t={t}
        />

        <CollectingCheckbox
          name="acceptence"
          label={contentForConsent}
          showErrors={showErrors}
          validations="isTrue"
          required
        />

        <button
          onClick={() => setIsSubmitted(true)}
          type="submit"
          className="modern-button modern-button-primary"
          disabled={showErrors}
        >
          {buttonText}
        </button>
      </Formsy>
    </div>
  );
};

export default CollectingLeadsForm;
