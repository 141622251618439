import { PictureData } from "../../../types/PictureData";

export enum LayoutEnum {
  GREEN = "green",
  ORANGE = "orange",
}

export type CollectingLeadsBlockData = {
  anchorId: string;
  buttonText: string;
  city: boolean;
  cityRequired: boolean;
  company: boolean;
  companyRequired: boolean;
  contentForConsent: string;
  contentUnderneath: string;
  contentUnderneathCollapsed: boolean;
  email: boolean;
  emailRequired: boolean;
  layout: LayoutEnum;
  lead: string;
  nameLead: boolean;
  nameLeadRequired: boolean;
  phone: boolean;
  phoneRequired: boolean;
  picture?: PictureData;
  positionLead: boolean;
  positionLeadRequired: boolean;
  postContent: string;
  question1: string;
  question1Required: boolean;
  question2: string;
  question2Required: boolean;
  question3: string;
  question3Required: boolean;
  question4: string;
  question4Required: boolean;
  surname: boolean;
  surnameRequired: boolean;
  title: string;
  background?: string;
  sendToEmail?: string;
  content?: string;
};

export type CollectingSelectOption = {
  value: string;
  label: string;
};

export type LeadData = {
  acceptence: boolean;
  city?: string;
  company?: string;
  email?: string;
  name?: string;
  surname?: string;
  phone_number?: string;
  phone_number_prefix?: string;
  position?: string;
  question1?: string;
  question2?: string;
  question3?: string;
  question4?: string;
};
