import React, { useEffect } from "react";
import { FormsyInjectedProps, withFormsy } from "formsy-react";

type PhoneNumberInputProps = {
  placeholder: string;
  errorMessage: string;
  showErrors: boolean;
  setErrorMessage: (msg: string) => void;
} & FormsyInjectedProps<string>;

const PhoneNumberInput: React.FunctionComponent<PhoneNumberInputProps> = ({
  value,
  setValue,
  placeholder,
  errorMessage,
  showErrors,
  setErrorMessage,
}): React.ReactElement => {
  const showError = showErrors && errorMessage;

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage, setErrorMessage]);

  return (
    <input
      className={showError ? "hasError" : ""}
      type="tel"
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      placeholder={placeholder}
    />
  );
};

export default withFormsy(PhoneNumberInput);
