import React, { useState } from "react";
import { CollectingLeadsBlockData, LayoutEnum, LeadData } from "./Types";
import CollectingLeadsForm from "./Form/CollectingLeadsForm";
import { getQuestions, UE_EVENT_NAME } from "./constraints";
import { useCitiesState } from "../../../utils/CitiesContext";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";
import { get } from "lodash";
import { sendLead as sendLeadApi } from "../../../utils/HttpClient";
import {
  collectingLeadsSend,
  collectingLeadsSendWithData,
} from "../../../utils/gtmEvents";
import UserEngageProvider from "../../../utils/UserEngageProvider";

type CollectingLeadsProps = {
  id: string;
  data: CollectingLeadsBlockData;
};

const CollectingLeads: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}) => {
  const { data, id: blockId } = blockData as CollectingLeadsProps;
  const [formSent, setFormSent] = useState(false);
  const { postContent, layout, picture, title, lead, anchorId = "" } = data;
  const isOrangeLayout = LayoutEnum.ORANGE === layout;
  const questionsArray = getQuestions(data);
  const { cities } = useCitiesState();

  const getBackground = (): React.CSSProperties => {
    return {
      background: !isOrangeLayout
        ? `url(${
            picture && picture.publicUrl
              ? process.env.NEXT_PUBLIC_IMG_URL + picture.publicUrl
              : "/blocks/CollectingLeads/green-background.svg"
          })`
        : "none",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundSize: "cover",
    };
  };

  const sendLead = async (data: LeadData): Promise<void> => {
    const phone = `${get(data, "phone_number_prefix.value", "")}${get(
      data,
      "phone_number"
    )}`;
    const currentUrl = window.location.href;
    const city = get(data, "city.label", "");
    const email = get(data, "email", "");
    const name = get(data, "name", "");
    const surname = get(data, "surname", "");

    const emailDetails = {
      ...data,
      city,
      blockId,
      phone_number: phone,
      src_url: currentUrl,
    };
    const popupName = anchorId;

    if (blockId) {
      await sendLeadApi(emailDetails);
    }
    collectingLeadsSendWithData(anchorId, email, city, phone, currentUrl);
    collectingLeadsSend();

    let pageHitEventData: Record<string, unknown> = {
      email: data.email,
      phone_number: phone,
      miasto_kursu: city ? city : "Online",
      lead_form: popupName,
      lead_source: "WWW",
      question_1: data.question1,
      question_2: data.question2,
      question_3: data.question3,
      question_4: data.question4,
    };

    let leadEventData: Record<string, unknown> = {
      email: data.email,
      phone_number: phone,
      miasto_kursu: city ? city : "Online",
      formularz: popupName,
      url: currentUrl,
      question1: data.question1,
      question2: data.question2,
      question3: data.question3,
      question4: data.question4,
    };

    if (name) {
      pageHitEventData = { ...pageHitEventData, first_name: name };
      leadEventData = { ...leadEventData, first_name: name };
    }

    if (surname) {
      pageHitEventData = { ...pageHitEventData, last_name: surname };
      leadEventData = { ...leadEventData, last_name: surname };
    }

    UserEngageProvider.pageHit(window, pageHitEventData);
    UserEngageProvider.eventWithName(window, UE_EVENT_NAME, leadEventData);

    setFormSent(true);
  };

  return (
    <div
      id={anchorId}
      className={`collectingLeads${
        isOrangeLayout ? " layout-orange" : " layout-green"
      }`}
    >
      <div
        style={getBackground()}
        className="collectingLeads__backgroundImage"
      />
      <div className="desktop-container collectingLeads__container">
        <div className="infoSection">
          {title && <h2 className="collectingLeads__title">{title}</h2>}
          {lead && (
            <div
              className="collectingLeads__leadContent"
              dangerouslySetInnerHTML={{ __html: lead }}
            />
          )}
        </div>
        {formSent ? (
          <div
            id={`${anchorId}-submitted`}
            className="collectingLeads__postContent"
            dangerouslySetInnerHTML={{
              __html: postContent,
            }}
          />
        ) : (
          <div className="collectingLeads__formContainer">
            {isOrangeLayout && (
              <img
                className="plane-lines-orangeImg"
                alt="plane-lines"
                src="/blocks/CollectingLeads/orange_bg.svg"
              />
            )}

            {!isOrangeLayout && (!picture || !picture.publicUrl) && (
              <>
                <img
                  className="plane-img"
                  src="/blocks/CollectingLeads/plane.svg"
                  alt="plane"
                />
                <img
                  className="plane-lines-img"
                  src="/blocks/CollectingLeads/lines.svg"
                  alt="plane-lines"
                />
              </>
            )}
            <CollectingLeadsForm
              validationServerErrors={{}}
              data={data}
              cities={cities.map((city) => ({
                value: city.cityNormalized,
                id: city.id,
                label: city.name,
              }))}
              questionsArray={questionsArray}
              handleSubmit={sendLead}
              anchorId={anchorId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectingLeads;
