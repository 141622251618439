import React, { ChangeEvent } from "react";
import { withFormsy, FormsyInjectedProps } from "formsy-react";
import { MAX_TEXT_AREA_VALUE_LENGTH } from "../../constraints";

type CollectingTextAreaProps = {
  label: string;
  placeholder?: string;
  showErrors: boolean;
} & FormsyInjectedProps<string>;

const CollectingTextArea: React.FunctionComponent<CollectingTextAreaProps> = ({
  label,
  placeholder,
  showErrors,
  errorMessage,
  value,
  setValue,
}): React.ReactElement => {
  const showError = showErrors && errorMessage;
  const valueLength = value ? value.length : 0;

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.currentTarget.value;
    if (value.length <= MAX_TEXT_AREA_VALUE_LENGTH) {
      setValue(event.currentTarget.value);
    }
  };

  return (
    <div className="collectingLeads__textAreaContainer">
      <div className="collectingLeads__label">
        {label && <label dangerouslySetInnerHTML={{ __html: label }} />}
      </div>

      <textarea
        className={showError ? "hasError" : ""}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
      <div className="collectingLeads__label">
        <span className="hasError">{showError ? errorMessage : ""}</span>
      </div>

      <span className="text-counter">
        {valueLength}/{MAX_TEXT_AREA_VALUE_LENGTH}
      </span>
    </div>
  );
};

export default withFormsy(CollectingTextArea);
