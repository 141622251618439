import React, { useState } from "react";
import PrefixSelect from "./PrefixSelect";
import PhoneNumberInput from "./PhoneNumberInput";
import { phoneValidator } from "../../../../../utils/validators";
import { TranslateFunction } from "../../../../../types/Translations";

type PhoneNumberWithPrefixInputProps = {
  label: string;
  placeholder: string;
  required: boolean;
  showErrors: boolean;
  t: TranslateFunction;
};

const PhoneNumberWithPrefixInput: React.FunctionComponent<
  PhoneNumberWithPrefixInputProps
> = ({ label, placeholder, showErrors, required, t }): React.ReactElement => {
  const propsForRequired = () => {
    if (required) {
      return {
        required: true,
        validations: {
          isPhoneValid: phoneValidator,
        },
        validationErrors: {
          isDefaultRequiredValue: t("dataForm-requiredValidation"),
          isPhoneValid: t("dataForm-phoneFormat"),
        },
      };
    }

    return {};
  };
  const selectPropsForRequired = () => {
    if (required) {
      return {
        required: true,
        validationErrors: {
          isDefaultRequiredValue: t("dataForm-requiredValidation"),
        },
      };
    }

    return {};
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [prefixErrorMessage, setPrefixErrorMessage] = useState("");

  return (
    <div>
      <div className="collectingLeads__label">
        {label && <label dangerouslySetInnerHTML={{ __html: label }} />}
        {((showErrors && errorMessage) ||
          (showErrors && prefixErrorMessage)) && (
          <span className="hasError">{errorMessage || prefixErrorMessage}</span>
        )}
      </div>

      <div className="phoneNumberWithPrefix">
        <PrefixSelect
          name="phone_number_prefix"
          showErrors={showErrors}
          setErrorMessage={setPrefixErrorMessage}
          {...selectPropsForRequired()}
        />
        <PhoneNumberInput
          name="phone_number"
          showErrors={showErrors}
          placeholder={placeholder}
          setErrorMessage={setErrorMessage}
          {...propsForRequired()}
        />
      </div>
    </div>
  );
};

export default PhoneNumberWithPrefixInput;
