export type UE = {
  pageHit: (data: Record<string, unknown>) => void;
};

export type UserEngage = (
  eventName: string,
  data: Record<string, unknown>
) => void;

declare global {
  interface Window {
    UE?: UE;
    userengage?: UserEngage;
  }
}

class UserEngageProvider {
  private static readonly userEngageApiKey = process.env.NEXT_PUBLIC_UE_KEY;
  static UE_EVENT_NAME = "event.lead_www_backup";
  static LEAD_SOURCE_VALUE = "WWW";

  static isAvailable(window: Window): boolean {
    return (
      !!this.userEngageApiKey &&
      window !== undefined &&
      !!window.userengage &&
      !!window.UE
    );
  }

  static pageHit(window: Window, data: Record<string, unknown>): void {
    window.UE?.pageHit({
      apiKey: this.userEngageApiKey,
      ...data,
    });
  }

  static eventWithName(
    window: Window,
    eventName: string,
    data: Record<string, unknown>
  ): void {
    window.userengage?.(eventName, {
      apiKey: this.userEngageApiKey,
      ...data,
    });
  }
}

export default UserEngageProvider;
