import React, { ChangeEvent, ReactElement } from "react";
import { FormsyInjectedProps, withFormsy } from "formsy-react";

type CollectingCheckboxProps = {
  label: string;
  showErrors: boolean;
} & FormsyInjectedProps<boolean>;

const CollectingCheckbox: React.FunctionComponent<CollectingCheckboxProps> = ({
  label,
  showErrors,
  value,
  setValue,
}): ReactElement => {
  const showError = showErrors && !value;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked = false } = event.target;
    setValue(checked);
  };

  return (
    <div className="collectingLeads__checkbox">
      <label>
        <span
          className={`customCheck${value ? " customCheck--checked" : ""}${
            showError ? " hasError" : ""
          }`}
        />
        <input type="checkbox" checked={value} onChange={handleOnChange} />
        <div
          className={`${showError ? "hasError" : ""}`}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </label>
    </div>
  );
};

export default withFormsy(CollectingCheckbox);
