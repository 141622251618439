import React, { useState } from "react";
import { TranslateFunction } from "../../../../../types/Translations";

type ContentUnderneathProps = {
  contentUnderneath: string;
  contentUnderneathCollapsed: boolean;
  t: TranslateFunction;
};

const ContentUnderneath: React.FunctionComponent<ContentUnderneathProps> = ({
  contentUnderneath,
  contentUnderneathCollapsed,
  t,
}): React.ReactElement => {
  const [dropDown, setDropDown] = useState(false);

  const toggleDropDown = (): void => {
    setDropDown(!dropDown);
  };

  return contentUnderneathCollapsed ? (
    <div className="collectingLeads__contentUnderneath">
      <div
        className={`collectingLeads__contentUnderneathInfo ${
          dropDown
            ? "collectingLeads__contentUnderneathInfo--active"
            : "collectingLeads__contentUnderneathInfo--notActive"
        }`}
        onClick={toggleDropDown}
        dangerouslySetInnerHTML={{
          __html: contentUnderneath,
        }}
      />

      <p
        onClick={toggleDropDown}
        className="collectingLeads__contentUnderneathShowMore"
      >
        {dropDown ? t("informer-showLess") : t("informer-showMore")}
      </p>
    </div>
  ) : (
    <div
      className="collectingLeads__contentUnderneathInfo"
      dangerouslySetInnerHTML={{
        __html: contentUnderneath,
      }}
    />
  );
};

export default ContentUnderneath;
