import React, { ChangeEvent, HTMLInputTypeAttribute } from "react";
import { withFormsy, FormsyInjectedProps } from "formsy-react";

type TextInputProps = {
  label: string;
  placeholder: string;
  showErrors: boolean;
  type?: HTMLInputTypeAttribute | undefined;
} & FormsyInjectedProps<string>;

const TextInput: React.FunctionComponent<TextInputProps> = ({
  label,
  placeholder,
  showErrors,
  type,
  value,
  setValue,
  errorMessage,
}): React.ReactElement => {
  const showError = showErrors && errorMessage;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value = "" } = event.currentTarget;
    setValue(value);
  };

  return (
    <div>
      <div className="collectingLeads__label">
        {label && <label dangerouslySetInnerHTML={{ __html: label }} />}
        {showError && <span className="hasError">{errorMessage}</span>}
      </div>

      <input
        type={type}
        className={showError ? "hasError" : ""}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default withFormsy(TextInput);
