import React from "react";
import { withFormsy, FormsyInjectedProps } from "formsy-react";
import Select, { SingleValue } from "react-select";
import { CollectingSelectOption } from "../../Types";

type SelectProps = {
  label: string;
  placeholder: string;
  showErrors: boolean;
  options: Array<CollectingSelectOption>;
} & FormsyInjectedProps<CollectingSelectOption | null>;

const CollectingSelect: React.FunctionComponent<SelectProps> = ({
  label,
  placeholder,
  showErrors,
  options,
  errorMessage,
  value,
  setValue,
}): React.ReactElement => {
  const showError = showErrors && errorMessage;

  const handleOnChange = (event: SingleValue<CollectingSelectOption>): void => {
    setValue(event ? event : null);
  };

  return (
    <div>
      <div className="collectingLeads__label">
        {label && <label dangerouslySetInnerHTML={{ __html: label }} />}
        {showError && <span className="hasError">{errorMessage}</span>}
      </div>

      <Select
        instanceId="collectingLeadsSelect"
        className={showError ? "Select hasError" : "Select"}
        classNamePrefix="Select"
        name="form-field-name"
        placeholder={placeholder}
        onChange={handleOnChange}
        options={options}
        value={value}
        isClearable
        isSearchable={false}
      />
    </div>
  );
};

export default withFormsy(CollectingSelect);
