import { CollectingLeadsBlockData } from "./Types";

export const MAX_TEXT_AREA_VALUE_LENGTH = 1000;

export const UE_EVENT_NAME = "event.lead_www_backup";

export type CollectingQuestion = {
  name: string;
  label: string;
  required: boolean;
};

export const getQuestions = (
  collectingLeadsData: CollectingLeadsBlockData
): Array<CollectingQuestion> => {
  const questionPhrase = "question";
  const requiredPhrase = "Required";
  const maxPhraseLength = 10;
  const questionsArray: Array<CollectingQuestion> = [];

  const questionsKeys = Object.keys(collectingLeadsData).filter(
    (key) => key.startsWith(questionPhrase) && key.length <= maxPhraseLength
  );

  questionsKeys.forEach((questionKey) => {
    const name =
      collectingLeadsData[questionKey as keyof CollectingLeadsBlockData];
    if (name) {
      const requiredKey: string = questionKey + requiredPhrase;

      questionsArray.push({
        name: questionKey,
        label: collectingLeadsData[
          questionKey as keyof CollectingLeadsBlockData
        ] as string,
        required: collectingLeadsData[
          requiredKey as keyof CollectingLeadsBlockData
        ] as boolean,
      });
    }
  });

  return questionsArray;
};
